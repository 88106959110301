/* 联盟专区路由 */
import asyncComponent from '@/components/asyncComponent/asyncComponent'
const route = [
  {
    // 联盟
    path: '/auth/league',
    component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/index')),
    routes: [
      {
        path: '/auth/league/articleManage',
        component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/articleManage/articleManage')),
        meta: {
          title: '文章管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/league/forumPostManage',
        component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/forumPostManage/forumPostManage')),
        meta: {
          title: '帖子管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/league/leagueMemberCheck',
        component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/leagueMemberCheck/leagueMember')),
        meta: {
          title: '新联盟成员审核 | 犇犇汽配联盟后台'
        }
      }
    ]
  }
]

export default route