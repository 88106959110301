import React, { Component } from 'react'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
// import ControlBoard from '@/components/controlBoard'
import PageFrame from '@/components/pageFrame'
import routeConfig from './routes'
import renderRoutes from './renderRoutes'
// import { AliveScope } from '@/components/keepAlive/keepAlive'
import { AliveScope } from 'react-activation'
const isDev = process.env.REACT_APP_ENV === 'development'
export class AppRoute extends Component {
  render () {
    return (
      <BrowserRouter>
        <div className="App">
          <Route path="/" render={props => {
            if (!isDev) {
              return null
            }
            // 控制台组件
            return <PageFrame />
          }}/>
          <AliveScope>
            <div id="fixedBox" className={isDev ? 'fixed-frame' : ''}>
              <Route path="/" exact render={props => {
                // 根路径默认跳转
                return <Redirect to="/auth/promotion/promotionPage" />
              }}/>
              {renderRoutes(routeConfig)}
            </div>
          </AliveScope>
        </div>
      </BrowserRouter>
    )
  }
}
