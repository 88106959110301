
import React from 'react'
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Icon } from 'antd';
import styles from './style.module.less'
import { setStaffDetail } from '@/store/actions'
import { findStaffMenuList } from '@/api/userInfo'

const { SubMenu } = Menu;

// @connect(mapStateToProps, mapDispatchToProps)
class SideMenu extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      intoFirst: true,
      collapsed: false,
      menuList: [],
      openKeys: [],
      selectedKeys: []
    }
  }

  // 生命周期
  componentDidMount () {
    this.getmenuList()
    this.props.setStaffDetail()
  }

  // 获取菜单列表
  getmenuList () {
    findStaffMenuList().then(res => {
      if (res.data.code === '200') {
        let data = res.data.data
        let location = this.props.location
        for (let i = 0; i < data.length; i++) {
          let returnBreak = false
          for (let j = 0; j <  data[i].childList.length; j++) {
            let item =  data[i].childList[j]
            if (location && location.pathname === item.url) {
              returnBreak = true
              this.setState({
                openKeys: [data[i].menuId],
                selectedKeys: [item.url]
              })
              break
            }
          }
          if (returnBreak) break
        }
        this.setState({
          menuList: data
        })
      }
    })
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  onOpenChange(openKeys){
    // const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    // console.log(openKeys, latestOpenKey)
    // if (latestOpenKey) {
    //   this.setState({
    //     openKeys: latestOpenKey ? [latestOpenKey] : []
    //   })
    // }
    if (openKeys.length === 1 || openKeys.length === 0) {
      this.setState({
        openKeys
      })
      return false
    }
    const latestOpenKey = openKeys[openKeys.length - 1]
    if (latestOpenKey.includes(openKeys[0])) {
      this.setState({
        openKeys
      })
    } else {
      this.setState({
        openKeys: [latestOpenKey]
      })
    }
  }

  onSelectChange(item){
    const latestSelectKey = this.state.selectedKeys.indexOf(item.key) === -1;
    if (latestSelectKey) {
      this.setState({
        selectedKeys: [item.key]
      })
      // this.props.history.push({
      //   pathname: item.key
      // })
    }
  }

  render() {
    const { menuList } = this.state

    return (
      <div className={`side-menu ${styles['side-menu']}`}>
        <Menu
          mode="inline"
          theme="light"
          openKeys={this.state.openKeys}
          selectedKeys={this.state.selectedKeys}
          inlineCollapsed={this.state.collapsed}
          onOpenChange={this.onOpenChange.bind(this)}
          onSelect={this.onSelectChange.bind(this)}
        >
          {menuList.map((submenu) => (
            <SubMenu
              key={submenu.menuId}
              title={
                <span>
                  <Icon type="setting" />
                  <span>{submenu.menuItem}</span>
                </span>
              }
            >
              {submenu.childList.map(item => (
                <Menu.Item key={item.url}>
                  <Link to={item.url}>{item.menuItem}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ))}
        </Menu>
      </div>
    );
  }
}

// 需要渲染什么数据
function mapStateToProps (state) {
  return {
    staffDetail: state.staffDetail
  }
}
// 需要触发什么行为
function mapDispatchToProps (dispatch) {
  return {
    setStaffDetail,
  }
}

/*
connect：它是一个高阶组件 有四个参数([mapStateToProps], [mapDispatchToProps], [mergeProps], [options])，后面两个参数可以不写，不写的话它是有默认值的。
        我们主要关注下前两个参数mapStateToProps和mapDispatchToProps。
        connect的使用方法是：把指定的state和指定的action与React组件连接起来，后面括号里面写UI组件名。
*/
SideMenu = connect(mapStateToProps, mapDispatchToProps)(SideMenu)

export default withRouter(SideMenu)
