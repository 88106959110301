import React from 'react'
import { connect } from 'react-redux'
import SideMenu from '@/components/sideMenu'
import style from './style.module.less'

@connect(mapStateToProps)
class ControlBoard extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
    }
  }

  render() {
    // const reactEnv = process.env.REACT_APP_ENV
    const { staffDetail } = this.props

    return (
      <div>
        <div className={style['pageHeader']}>犇犇汽配运营后台
          <span className="fr">{staffDetail.userName}</span>
        </div>
        <SideMenu></SideMenu>
        {/* {renderRoutes(this.props.route.routes)} */}
        sdfasdf
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    staffDetail: state.staffDetail
  }
}

export default ControlBoard
