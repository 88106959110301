import React from 'react'
import renderRoutes from '@/router/renderRoutes'

export default class Login extends React.Component {
  render() {
    return (
      <div>
        登录
        {renderRoutes(this.props.route.routes)}
      </div>
    )
  }
}